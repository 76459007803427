@tailwind base;
@tailwind components;
@tailwind utilities;

.project{
    font-family: SF Pro Display,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  }
  
  .map {
    background-image: url('https://source.unsplash.com/dWwZBcjw3GE');
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    height: 100vh;
  }

  .faq {
    background-image: url('https://source.unsplash.com/oZuBNC-6E2s');
    background-size: cover; /* Adjust based on your preference */
    background-position: center; /* Adjust based on your preference */
    background-repeat: no-repeat; 
    height: 80vh;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d4d5d6; /* Change the color as needed */
    border-radius: 8px;
    border: 3px solid #c5cbcf; /* Change the color as needed */
  }

  input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}


.project{
  font-family: SF Pro Display,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
}

/* Webkit (Safari/Chrome) */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 6px;
  border: 3px solid #000;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

/* Firefox */
* {
  scrollbar-color: #a6a6a6 #f4f4f4;
  scrollbar-width: thin;
}

/* Edge */
* {
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 6px;
  border: 3px solid #fff;
}

*::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: '';
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #076fe5;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(20% - 6.8px);
  top: calc(20% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {

  0%,
  80%,
  100% {
    opacity: .75;
    box-shadow: 0 0 #076fe5;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #076fe5;
    height: 40px;
  }
}


/* Calender css */
/* Default font size for non-mobile */
.fc .fc-toolbar-title,
.fc .fc-button {
  font-size: 15px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .fc .fc-toolbar-title,
  .fc .fc-button {
    font-size: 10px;
  }
}

